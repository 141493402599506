/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
//__<<ngThemingMigrationEscapedComment1>>__
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-lprx-green: (
  50: #f0f4e8,
  100: #dae3c5,
  200: #c2d09e,
  300: #a9bd77,
  400: #96ae59,
  500: #84a03c,
  600: #7c9836,
  700: #718e2e,
  800: #678427,
  900: #54731a,
  A100: #e1ffad,
  A200: #ceff7a,
  A400: #bbff47,
  A700: #b1ff2e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$lprx-app-primary: mat.define-palette($mat-lprx-green);
$lprx-app-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

//$lprx-app-accent:  mat-palette($mat-indigo);
//$lprx-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lprx-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$lprx-app-theme: mat.define-light-theme($lprx-app-primary, $lprx-app-accent, $lprx-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lprx-app-theme);

//@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
// @import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css');
//@import url('https://cdnjs.cloudflare.com/ajax/libs/dragula/3.7.2/dragula.css');

/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */

// stylelint-disable
$white: #fff !default;
$gray-100: #f4f4f5 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #84a03c !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$primary: $green !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$font-size-base: 0.9rem !default; // Assumes the browser default, typically `16px`
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$border-radius: 0.15rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.075rem !default;

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.btn-em {
  cursor: pointer;
}

.mat-mdc-cell {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base - 0.1;
  color: $gray-900;
}

.mat-mdc-header-row,
.mat-mdc-row {
  padding: 0 15px;
}

$list-group-border-color: rgba(230, 230, 230, 0.7);

a:hover {
  cursor: pointer;
}

a[ng-reflect-router-link]:not([href]):not([tabindex]):not([appbutton]) {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'ngx-toastr/toastr-bs4-alert';
.gu-mirror {
  pointer-events: none !important;
  opacity: 0.15;
}

.gu-transit {
  opacity: 0 !important;
}

.drop-area .gu-transit {
  display: none;
}

/* Sticky footer styles (not so sticky now)
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  //margin-bottom: 60px; __<<ngThemingMigrationEscapedComment4>>__

  background-color: #f4f4f5;

  .footer {
    //position: absolute;
    //bottom: 0;
    width: 100%;
    //height: 60px; __<<ngThemingMigrationEscapedComment5>>__
    line-height: 30px; /* Vertically center the text there */
    //background-color: #f5f5f5;
    padding: 30px 0;
  }

  &.bg-gray {
    background-color: #f5f5f5;

    .footer {
      background-color: #f5f5f5 !important;
    }
  }

  &.bg-light {
    .footer {
      background-color: #f4f4f5 !important;
    }
  }
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 63px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding: 60px 20px 20px 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #65a30d;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

app-recipe-search-result.gu-mirror {
  background: white;
  border: 2px dashed #ccc;
  border-radius: 5px;
  opacity: 0.9;
  overflow: hidden;
  transform: scale(0.8);
}

app-planner-card.gu-mirror {
  transform: scale(0.75);
  opacity: 0.8;
}

.remover {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.mover {
  cursor: move;

  &:hover {
    color: green;
  }
}

.btn-bare {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
}

.btn-group-toggle label.btn-primary.btn {
  &.active {
    background-color: $primary;
    border-color: $primary;
  }

  &:not(.active) {
    background-color: white;
    color: #666;
  }
}

:not(.ingredients) {
  input {
    &.ng-untouched[required] {
      //border-left: 3px #b1c181 solid;
    }

    //&.ng-invalid.ng-touched {
    //    border-left: 3px red solid;
    //}

    &.ng-valid {
      border: 3px #65a30d solid;
    }

    &.ng-invalid,
    &.lprx-invalid {
      &.ng-touched {
        border: 1px #dc2626 solid;
      }
    }
  }
}

.form-control::placeholder {
  color: #c3c3c3;
  opacity: 1;
}

app-planner-card {
  transition: all 0.5s;
  display: block;
  position: relative;

  .note {
    max-height: 100px;
    overflow: hidden;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@media print {
  app-planner-card .note {
    max-height: none !important;
    overflow: hidden;
    font-size: 9pt;
  }
}

.planner-wrapper.layout-layered {
  div.day {
    &:not(.isToday1) app-planner-card + app-planner-card {
      margin-top: -137px;
      display: block;
      /* z-index: 1; */
      position: relative;
      box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
      left: 0px;
      transition: all 0.5s;
    }

    app-planner-meal:hover app-planner-card + app-planner-card {
      margin-top: 0;
    }

    &:hover app-planner-card + app-planner-card {
      margin: 0;
    }
  }
}

.planner-wrapper.dragging,
.planner-wrapper.layout-compressed,
.print-content {
  .recipe > div {
    display: flex;
    align-items: center;
  }

  .title {
    max-height: 48px !important;
    flex: 1;
    overflow: hidden;
    min-height: 0 !important;
    font-size: 11px !important;
    padding: 0 7px !important;
  }

  .card-image {
    height: 70px !important;
    width: 70px !important;
    display: block;
  }

  app-planner-card {
    margin-top: 0 !important;
    box-shadow: none !important;
  }

  .remove-card {
    //display: none !important;
  }

  .meal-empty-space {
    //min-height: 40px !important;
  }
}

// @media screen and max width of 1200px
@media screen and (max-width: 1200px) {
  .planner-wrapper.dragging,
  .planner-wrapper.layout-compressed,
  .print-content {
    .card-image {
      height: 40px !important;
      width: 40px !important;
      display: block;
    }
  }

  app-planner-layout-wide {
    & > .container-fluid,
    main {
      padding: 0 !important;
    }
  }
}

@media print {
  @page {
    size: letter;
  }

  /* Hide Zendesk support bubble */
  iframe#launcher {
    display: none;
  }

  .print-content {
    .ingredient-list,
    .direction-list {
      li {
        font-size: 14px;
      }
    }

    .recipe-image {
      text-align: center;

      img {
        max-width: 50% !important;
      }
    }

    .card-image {
      img {
        visibility: visible !important;
      }
    }

    .grocery-list-wrapper {
      h5 {
        font-size: 16px;
      }
    }

    .element-header {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }

  @for $i from 1 through 12 {
    .col-print-#{$i} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 #{percentage(calc(($i * 8.33) / 100))};
      flex: 0 0 #{percentage(calc(($i * 8.33) / 100))};
      max-width: #{percentage(calc(($i * 8.33) / 100))};
    }
  }

  /*
  On Windows the default semi-transparent color creates font rendering issues
  when printing to PDF. Solid font colors don't have this problem.
  */
  .mat-drawer-container {
    color: #000 !important;
  }
}

@media not print {
  .print-content {
    .grocery-list-calendar {
      display: none;
    }

    #weekly-planner-print-container,
    .print-card {
      border: 2px dashed #bbb;
      padding: 0 30px;
      transform: scale(0.9);

      .print-only {
        display: -webkit-box;
        display: flex;
      }
    }

    .print-grocery-list-wrapper {
      border: 2px dashed #bbb;
      padding: 30px;
      transform: scale(0.9);
    }

    #weekly-planner-wrapper {
      top: 0 !important;
      transform: rotate(0) !important;
    }
  }
}

@media print {
  .print-grocery-list-wrapper {
    transform: scale(0.95);
  }
}

.print-content {
  &.hide-public-notes .public-note,
  &.hide-private-notes .private-note,
  &.hide-nutrition-facts .nutrition-facts-wrapper,
  &.hide-nutrition-facts app-nutrition-summary,
  .remove-card,
  .weekly-options,
  app-unit-system-toggle,
  .btn-servings-adjust {
    display: none !important;
  }

  .non-calendar-day-name {
    display: none;
  }

  .day-name {
    text-align: right;
    display: inline-block !important;
  }

  .grocery-list-wrapper {
    .col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .item {
      font-size: 14px !important;
      width: 31% !important;
      padding: 0 !important;
      margin-bottom: 1px !important;
      line-height: normal;

      .material-icons {
        font-size: 19px;
      }
    }
  }
}

h3 {
  .ngb-dp-week:hover:not(.ngb-dp-weekdays),
  .ngb-dp-day:hover {
    background-color: $green !important;
    color: #fff !important;

    * {
      background-color: $green !important;
      color: #fff !important;
    }
  }
}

.ngb-dp-weekday {
  color: $green;
}

.StripeElement {
  margin: 1rem 0 1rem;
  background-color: white;
  padding: 0px 12px;
  border-radius: 4px;
  border: 1px solid #cfd5db;
  //box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement *::placeholder {
  opacity: 1;
  color: #b9c0c8;
}

button[disabled] {
  pointer-events: none;
  cursor: crosshair;

  &:after {
  }
}

.disable-drag {
  .remove-card {
    display: none !important;
  }
}

.pbr {
  display: inline-block;
}

a[routerLink]:not(.btn):not([tabindex]):not(.nav-link):not([appbutton]) {
  color: #65a30d;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #566827;
    text-decoration: underline;
  }
}

div.ngb-dp-day {
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  .custom-day.focused {
    background-color: #e6e6e6;
  }

  .custom-day.range,
  .custom-day:hover {
    background-color: #65a30d;
    color: white;
  }

  .custom-day.faded {
    background-color: rgba(183, 223, 82, 0.58);
  }

  &.disabled {
    .custom-day {
      opacity: 0.5;
      background-color: #dfdfdf;
      color: #878787;
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //z-index: 1040;
  background-color: rgba(255, 255, 255, 0.58);
}

.modal {
  z-index: 1060 !important;
}

.modal-content {
  border: none;
  // box-shadow: 5px 5px 5px rgba(0, 0, 0, .5);
  box-shadow: 5px 5px 150px rgba(0, 0, 0, 0.5);
  border-radius: 0;
}

.headings {
  span {
    cursor: pointer;
  }

  i {
    display: none;
  }
}

.sorted-by {
  font-style: italic;

  i {
    display: inline !important;
  }
}

.sort-asc {
  .sorted-by:after {
  }
}

.is-admin {
  .recipe.is-deleted {
    background-color: #ffcecc !important;
  }
}

#admin-wrapper .card {
  //box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  border: none;

  .card-header {
    background-color: #fff;

    :last-child {
      margin-bottom: 0;
    }
  }
}

.dropdown-toggle.no-carat::after {
  display: none;
}

.text-light a {
  color: #f8ffc5;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #dee2e6 !important;
}

.border-top-dashed {
  border-top: 1px dashed #dee2e6 !important;
}

.belongs-to-user-icon {
  //position: absolute;
  //bottom: 18px;
  //right: 3px;
  color: #5e722b;
  //padding: 3px;
}

app-create-or-edit-note-modal {
  .fr-toolbar.fr-top {
    top: 0px;
    border: 0;
    box-shadow: none;
    border-bottom: 1px solid #e9ecef;
  }

  .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
  }

  .fr-quick-insert {
    display: none !important;
  }
}

button {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

a.clickable-link {
  text-decoration: underline;
  color: #5e722b;
}

//ngb-modal-window.note .modal-content {
//  background-color: #ffffc8;
//}

.note-card-mobile p:last-child {
  margin-bottom: 0;
}

.modal-info {
  padding: 10px 15px;
  border-bottom: 1px solid #e9ecef;
  color: #b5b5b5;
  font-size: 13px;
}

.toggle-on {
  color: #ffc107;
}

.toggle-off {
  color: rgba(0, 0, 0, 0.38);
}

app-planner-meal,
app-recipe-search-result {
  .popover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
  }

  .popover-body {
    padding: 0;

    app-nutrition-summary {
      border: none;
    }
  }
}

.card {
  border: none;
  background: #fff;
  border-radius: 0.75rem;

  //box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  //box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.1) 0 2px 2px -1px !important;
  -webkit-transition: all 0.3s ease-in-out;
  margin-bottom: $grid-gutter-width;

  .card-header {
    border-bottom: 1px solid #e6e6e6;
    background: #fff;
    border-radius: 0.75rem 0.75rem 0 0;

    .card-title {
      font-size: 15px;
      margin-bottom: 0;
    }

    .btn.btn-link {
      margin-left: 10px;
      padding: 0;
    }
  }

  .list-group-flush:last-child {
    border-radius: 0 0 0.75rem 0.75rem;
  }

  & > .list-group:first-child {
    & > .list-group-item:first-child {
      border-radius: 0.75rem 0.75rem 0 0;
    }

    & > .list-group-item:last-child {
      border-radius: 0 0 0.75rem 0.75rem;
    }
  }

  & > ngb-tabset {
    .nav-tabs {
      border-bottom: 1px solid #e6e6e6;

      .nav-item {
        .nav-link {
          border: none;
          border-bottom: 4px solid transparent;
          border-radius: 0;

          &.active {
            border-bottom: 4px solid $primary;
            color: #fff;
            background-color: $primary;
          }
        }

        &:first-child {
          .nav-link.active {
            border-radius: 0.75rem 0 0 0;
          }
        }

        &:last-child {
          .nav-link.active {
            border-radius: 0 0.75rem 0 0;
          }
        }
      }
    }
  }

  .card-footer {
    background-color: white;
    box-shadow: none;
    border-radius: 0 0 0.75rem 0.75rem;
  }
}

.view-toggler {
  border-bottom: 1px solid #e6e6e6;

  a {
    margin: 0;
    border-radius: 0;
  }
}

.list-group-item {
  border: none;
  border-bottom: 1px solid $list-group-border-color;
  margin-bottom: 0;
}

//.view-title {
//  font-weight: 200;
//  font-size: 1.8em;
//  line-height: normal;
//  margin: 20px 0;
//  padding: 0;
//}

.card-body {
  > form > hr,
  > hr {
    margin: 40px -20px 30px -20px;
    border: none;
    border-bottom: 1px dotted #ccc;
  }
}

.nav-link {
  .prime-books {
    display: none;
  }

  .prime-books-gray {
    display: inline;
  }

  &.active {
    .prime-books {
      display: inline;
    }

    .prime-books-gray {
      display: none;
    }
  }
}

.btn + .btn {
  margin-left: 15px;
}

.dropdown-menu {
  .btn + .btn {
    margin-left: 0px;
  }
}

/* Override defaults of Angular Material theme */
.mat-drawer-container {
  background-color: #f4f4f5;
}

body.focused-form {
  &,
  .mat-drawer-container {
    background-color: #f4f4f5;
  }

  .footer {
    max-width: 380px;
    border-radius: 0.75em;
    margin: auto auto 20px;
  }
}

tr.mat-mdc-row.cdk-row.ng-star-inserted:hover * {
  background-color: aliceblue;
}

app-recipe-box-result {
  .mat-drawer-backdrop.ng-star-inserted.mat-drawer-shown {
    position: fixed;
  }
}

ngx-slider.ngx-slider {
  // https://github.com/angular-slider/ngx-slider/blob/master/STYLING.md

  .ngx-slider-bar {
    height: 2px;
  }

  .ngx-slider-selection {
    background: $primary !important;
  }

  .ngx-slider-pointer {
    width: 8px;
    height: 16px;
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: $primary;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ngx-slider-pointer:after {
    display: none;
  }

  .ngx-slider-bubble {
    bottom: 14px;
  }

  .ngx-slider-tick.ngx-slider-selected {
    background-color: $primary;
  }

  .ngx-slider-bubble {
    display: none;
  }
}

.recipe-notes img {
  max-width: 100% !important;
}

s {
  opacity: 0.6;
}

.print-content .card-image {
  height: 50px !important;
  width: 50px !important;
  display: block;
}

.cdk-drag-placeholder {
  opacity: 1 !important;
}

app-planner-meal:has(.cdk-drop-list-dragging) {
  .meal-name {
    background-color: rgba(132, 160, 60, 0.17) !important;

    & > div {
      font-weight: bold;
    }
  }
}

.no-select * {
  //user-select: none;
  //--webkit-user-select: none;
}

img,
a {
  -webkit-touch-callout: none;
}

$deleteIconSize: 40px;
$offsetFadeX: 50px;

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    //translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    //translateX(-50%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.remove-recipe-bin {
  position: fixed;
  opacity: 0;
  //bottom: 50px;
  top: 67px;
  left: 39.5%;
  text-align: center;
  height: $deleteIconSize + 2;
  width: $deleteIconSize + 2;
  box-shadow: 0 0 5px #c90000, 0 0 0 18px #ffffff;
  border-radius: 100px;
  background-color: white;
  border: 1px solid #c90000;
  z-index: 1000000;

  transition: opacity 0.5s ease-in-out, top 0.25s ease-in-out;

  mat-icon {
    height: $deleteIconSize;
    width: $deleteIconSize;
    font-size: $deleteIconSize;
    color: red;
  }

  &.cdk-drop-list-receiving,
  &.cdk-drop-list-dragging {
    opacity: 1;
    top: 77px;

    //-webkit-animation-duration: 0.25s;
    //animation-duration: 0.25s;
    //-webkit-animation-fill-mode: both;
    //animation-fill-mode: both;
    //-webkit-animation-name: fadeIn;
    //animation-name: fadeIn;
  }

  &.cdk-drop-list-dragging {
    display: block;
    box-shadow: 0 0 10px red;
    background-color: #c90000;

    mat-icon {
      color: white;
    }
  }

  app-recipe-drag {
    display: none !important;
  }
}

.drag-handle {
  cursor: move;
}

.logo-for-print {
  display: none;
}

@media print {
  body.print-recipe {
    app-root,
    .modal-header,
    .modal-footer {
      display: none;
    }

    .logo-for-print {
      display: block;
      max-width: 200px;
      max-height: 100px;
      margin-bottom: 10px;

      img {
        max-width: 100%;
        max-height: 100px;
      }
    }

    .modal-dialog {
      max-width: 999999999px !important;
    }

    ngb-modal-window {
      min-width: 992px;
      margin: 0 !important;
      padding: 0 !important;
      display: block !important;
      position: relative;
    }
  }
}

// tailwindcss
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 86, 73%, 66%;
    --primary-100: 86, 73%, 85%;
    --primary-300: 86, 71%, 73%;
    --primary-600: 85, 85%, 35%;
    --primary-800: 86, 69%, 23%;

    --secondary: 240, 6%, 10%;
    --secondary-0: 0, 0%, 100%;
    --secondary-50: 0, 0%, 98%;
    --secondary-100: 240, 5%, 96%;
    --secondary-200: 240, 6%, 90%;
    --secondary-300: 240, 5%, 84%;
    --secondary-400: 240, 5%, 65%;
    --secondary-500: 240, 5%, 65%;
    --secondary-600: 240, 5%, 34%;
    --secondary-700: 240, 5%, 26%;
    --secondary-900: 240, 6%, 10%;

    --brand-grey-10: 0, 0%, 88%;
    --brand-white: 0, 0%, 100%;

    --danger: 0, 72%, 51%;
    --danger-100: 0, 93%, 94%;
    --danger-500: 0, 84%, 60%;

    --content-magenta: 320, 100%, 35%;
    --content-amber: 26, 90%, 37%;
    --content-blue: 226, 71%, 40%;
    --content-teal: 175, 77%, 26%;

    --radius: 0.5rem;
  }

  .dark {
    --primary: 86, 73%, 66%;
    --primary-100: 86, 73%, 85%;
    --primary-300: 86, 71%, 73%;
    --primary-600: 85, 85%, 35%;
    --primary-800: 86, 69%, 23%;

    --secondary: 240, 6%, 10%;
    --secondary-0: 0, 0%, 100%;
    --secondary-50: 0, 0%, 98%;
    --secondary-100: 240, 5%, 96%;
    --secondary-200: 240, 6%, 90%;
    --secondary-300: 240, 5%, 84%;
    --secondary-400: 240, 5%, 65%;
    --secondary-500: 240, 5%, 65%;
    --secondary-600: 240, 5%, 34%;
    --secondary-700: 240, 5%, 26%;
    --secondary-900: 240, 6%, 10%;

    --danger: 0, 72%, 51%;
    --danger-100: 0, 93%, 94%;
    --danger-500: 0, 84%, 60%;

    --content-magenta: 320, 100%, 35%;
    --content-amber: 26, 90%, 37%;
    --content-blue: 226, 71%, 40%;
    --content-teal: 175, 77%, 26%;

    --radius: 0.5rem;
  }

  input.form-control:not([type='checkbox']):not([type='radio']):not([type='file']),
  input.form-control[type='text'],
  select,
  textarea {
    @apply tw-text-sm tw-px-4 tw-py-2.5 tw-border tw-rounded-md
      placeholder:tw-text-secondary-500
      focus:tw-outline-primary-600 focus:tw-outline-offset-[0.25rem]
      disabled:tw-placeholder-secondary-700 disabled:tw-text-secondary-700 disabled:tw-cursor-not-allowed
      hover:tw-border-secondary-400 #{!important};
    height: auto !important;

    // on focus and focus-within, set outline-style to auto
    &:focus,
    &:focus-visible {
      outline-style: auto !important;
    }
  }

  .btn-primary {
    @apply tw-text-secondary-900 tw-bg-primary #{!important};
  }

  .view-title {
    @apply tw-mt-12 tw-text-3xl tw-font-semibold;
  }

  .tw-pill {
    // @apply inline-flex items-center justify-center rounded-3xl gap-2 text-sm focus-visible:ring-2 focus-visible:ring-primary-600 focus-visible:ring-offset-2 focus:outline-none disabled:opacity-50 transition duration-150 ease-[cubic-bezier(0.4, 0.0, 0.2, 1)] bg-secondary-0 font-normal border border-secondary-300 hover:bg-secondary-50 hover:border-secondary-400 focus-visible:outline-primary-600 px-3 py-[0.5625rem] undefined flex-shrink-0;
    @apply tw-inline-flex tw-items-center tw-justify-center tw-rounded-3xl tw-gap-2 tw-text-sm focus-visible:tw-ring-2 tw-ring-primary-600 tw-ring-offset-2 focus:tw-outline-none disabled:tw-opacity-50 tw-transition tw-duration-150 tw-bg-secondary-0 tw-font-normal tw-border tw-border-secondary-300 hover:tw-bg-secondary-50 hover:tw-border-secondary-400 focus-visible:tw-outline-primary-600 tw-px-3 tw-py-[0.5625rem] tw-flex-shrink-0;
  }
}

input.form-control:focus,
textarea.form-control:focus {
  outline: 3px solid !important;
  box-shadow: none !important;
}

//
//@layer base {
//  * {
//    @apply border-secondary-300 font-sans;
//  }
//  body {
//    @apply text-secondary;
//    //@apply bg-secondary-100 text-secondary;
//  }
//}

.tw-no-underline {
  text-decoration: none !important;
}

a[appbutton]:hover {
  // opacity: 1;
  // color: hsl(240 6% 10% / var(--tw-text-opacity));
  color: hsl(240 6% 10%);
}

a[appbutton='danger']:hover {
  color: white;
}

// remove borders from btn-group
@layer components {
  .btn-group {
    @apply tw-border tw-rounded-3xl tw-border-primary-300;

    .btn {
      @apply tw-border tw-border-primary-300;

      // first rounded
      &:first-child {
        @apply tw-rounded-l-3xl;
      }

      // last rounded
      &:last-child {
        @apply tw-rounded-r-3xl;
      }
    }
  }
}

//// fix input-group
.input-group
  > input.form-control:not(:last-child):not([type='checkbox']):not([type='radio']):not([type='file']) {
  border-radius: 0.375rem 0 0 0.375rem !important;
}

mat-checkbox label {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

app-distributor-dashboard .mat-drawer.mat-drawer-push {
  background-color: #10162f !important;
}

.pill {
  border-radius: 100px !important;
}

#ngb-live.visually-hidden {
  display: none;
}

.list-group-flush > .list-group-item:hover {
  background-color: #f8fdf2;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff !important;
}

.no-br {
  // no break within
  white-space: nowrap;
}

.alert a {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}
